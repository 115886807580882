<template>
    <div class="items__container" v-if="data">
        <div class="item__wrapper"
            v-for="(item, index) in data"
            :key="`layout-item-${item.id}${index}`" >

            <Item
                :item="item"
                @selected="$emit('submit', item)"
            />
            <div v-if="!isBulkEdit && !item.disabled && showPrice" class="item__price item__title" :class="{ disabled: item.disabled }">
                From
                <Loader v-if="!item.price_from" />
                <template v-else>{{ formatter.formatMoney(item.price_from) }}</template>
            </div>
            <div class="item__disabled_note" v-if="item.disabled">(restricted by size)</div>

        </div>
    </div>
</template>

<script>
import Item from './elements/Item'
import Loader from '../elements/Loader.vue'
import api from '../../services/wizard.service'
import { useFormatter } from '../../composables/formatter'
import { useLimitsLogic } from '../../composables/estimate/limits'
import { ref, computed } from 'vue'
import { useStore } from 'vuex'

export default({
    components: {
        Item,
        Loader
    },
    props: ['items', 'currentStep', 'stepName', 'allData', 'isBulkEdit', 'mode', 'area'],
    setup(props) {
        const formatter = useFormatter()
        const data = ref(null)

        //check disabled items
        const limitsLogic = useLimitsLogic(props)
        const store = useStore()

        const showPrice = computed(() => store.getters['auth/showPrice'](props.area))

        getData()
        function getData() {
            data.value = props.items.data.map(el => {
                const disabled = limitsLogic.getDisabledValue(el.limits)

                return {
                    ...el,
                    price_from: '',
                    disabled,
                }
            })
            getFromPriceForItems()
        }

        function getFromPriceForItems() {
          if (!props.isBulkEdit) {
            data.value.forEach(async (el) => {
              const type = props.stepName.toLowerCase()
              const id = el.id

              const {current_step, steps, container_id, mode, position_id} = props.allData
              const result = await api.getFromPrice(type, id, {
                current_step,
                steps,
                container_id,
                position_id,
                mode: props.mode
              })
              el.price_from = result.min_price || ''
            })
          }
        }



        return {
            showPrice,
            data,
            formatter
        }
    }
})
</script>


<style scoped lang="scss">
.items__container {
    margin-top: -40px;
    min-height: auto!important;
    ::v-deep .item {
        margin-bottom: 0;
    }
     @media(max-width: 768px) {
        margin-top: 0;
    }
}
::v-deep .item__title {
    min-height: auto;
}
.item__disabled_note {
  text-align: center;
  font-size: 0.85rem;
  color: darkred;
}

.item__price {
    text-align: center;
    display: flex;
    justify-content: center;


    &.disabled {
        color: #777777;
    }
    ::v-deep .spinner svg {
        font-size: 17px!important;
        margin-left: 5px;
    }
}

</style>
