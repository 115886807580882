<template>
    <div class="item" :class="{'disabled': item.disabled}" @click="onClick">
        <div class="item__icon" >
            <svg-icon-qouting :data="item.icon" :color="item.disabled && '#777777'"/>
        </div>
        <div class="item__title">
            <span v-if="item.formatted_name" v-html="item.formatted_name" />
            <span v-else>{{item.name}}</span><br/>

            <HelpTopic v-if="item.help_topic_url" :iframe-data="item.help_topic_url"/>
          <span v-if="item.edit" @click.stop="$emit('edit')">
              <svg-icon name="solid/cog" />
            </span>
          <span v-if="item.delete" @click.stop="$emit('delete')">
              <svg-icon name="solid/trash" />
            </span>
        </div>
    </div>

  <Modal class="warning__modal" v-model="showCannotProductModal" v-if="showCannotProductModal">
    <div style="width: 100%">
      <div class="modal__desc">
        Unfortunately, these items cannot be produced. Apologies for inconvenience
        <br>
      </div>
    </div>
  </Modal>


</template>

<script>
import HelpTopic from '../../elements/HelpTopic.vue'
import {ref} from 'vue'
import Modal from '../../elements/Modal'

export default({
    components: {
        HelpTopic,
      Modal
    },
    props: ['item', 'disabledNote'],
    setup(props, { emit }) {
      const showCannotProductModal = ref (false)

        function onClick() {
            if(props.item.disabled) return
          if (props.item.can_produce == 0) {
            showCannotProductModal.value = true
            return
          }

            emit('selected')
        }

        return {
            onClick,
          showCannotProductModal
        }
    },
})
</script>


<style scoped lang="scss">
.item {
    margin: 10px 10px 0;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    /*@media(max-width: 768px) {
        margin: 10px 15px;
    }*/
    &.disabled {
        cursor: default;
        .item__title {
            color: #777777;
        }
        .item__icon {
          filter: grayscale(1);
        }
    }
    &__title {
        max-width: 180px;
        font-size: 15px;
        line-height: 140%;
        // text-transform: uppercase;
        color: #000000;
        margin-top: 17px;
        min-height: 42px;
        display: inline;
    }
    &__icon, &__icon ::v-deep svg {
        // max-height: 150px;
        font-size: 150px;
        @media(max-width: 768px) {
            font-size: 120px;
        }
    }
    &__icon .icons__wrapper {
        display: flex;
    }
    &__title {
        text-align: center;
        margin-top: 5px;
    }
}
.warning__modal {
  ::v-deep(.modal) {
    width: 470px;
    height: 100px;
  }
}

/*@media (max-width: 767px){
  .item__title {
    max-width: 140px!important;
  }

}*/

</style>
